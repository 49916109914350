<div
  class="roadbook-list-item d-block d-md-flex align-items-center flex-grow-1 p-3"
  role="button"
>
  <div class="logo-event-wrap">
    <div
      [className]="'__avatar_event _avatar-' + terrain?.code ?? 'default'"
    ></div>
  </div>
  <div class="d-flex align-items-center flex-grow-1">
    <div
      class="details-wrap d-flex flex-column pt-3 pt-xs-3 pt-sm-3 pt-md-0 pl-md-3 w-100"
      (click)="editOrValidateRoadbook()"
    >
      <div class="d-flex _name p-1">
        <h4>{{ roadbook?.name }}</h4>
      </div>
      <div class="d-flex flex-column">
        <div>
          <span class="event_date">{{
            race?.date | date : "EEE dd MMM y"
          }}</span>
        </div>
        <div class="d-flex">
          <span>{{ race?.locality }}</span>
          <span *ngIf="race?.postalCode"
            >&nbsp;({{ race?.postalCode | slice : 0 : 2 }})
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="d-sm-block d-flex flex-column">
    <div class="d-flex align-items-center justify-content-end">
      <div *ngIf="roadbook.race?.status === 'published'">
        <div class="interval-label-wrap mr-2">
          <span
            class="badge"
            [ngClass]="{
              'bg-default': intervalToRace <= 0,
              'bg-success':
                intervalToRace > 0 && intervalToRace > intervalSoonLimit,
              'bg-warning':
                intervalToRace > 0 && intervalToRace <= intervalSoonLimit,
              'bg-danger':
                intervalToRace > 0 && intervalToRace <= intervalImminentLimit
            }"
          >
            <strong>
              {{ intervalToRacePrefix }}
              {{ intervalToRaceLabel }}
            </strong>
          </span>
        </div>
      </div>

      <div *ngIf="roadbook.race?.status === 'canceled'" class="chip-list">
        <div class="chip disabled">{{ roadbook.race?.status | translate }}</div>
      </div>

      <div
        class="d-flex flex-column"
        *ngIf="intervalToRace < 0 && roadbook.status === 'validated'"
      >
        <span
          class="value"
          [ngClass]="{
            'text-success':
              roadbook?.measuredTimeOfArrival <
              roadbook?.estimatedTimeOfArrival,
            'text-warning':
              roadbook?.measuredTimeOfArrival > roadbook?.estimatedTimeOfArrival
          }"
          >Réel :
          {{
            roadbook.measuredDuration && roadbook.measuredDuration !== 0
              ? (roadbook.measuredDuration | duration)
              : "..h..m..s"
          }}</span
        >
        <span class="value _estimated-value"
          >Estimé :
          {{ (roadbook.estimatedDuration | duration) || "..h..m..s" }}</span
        >
      </div>

      <div class="d-flex" *ngIf="roadbook.race?.status !== 'canceled'">
        <button
          *ngIf="intervalToRace > 0"
          (click)="editRoadbook()"
          class="_sp_btn _inline_icon _white _small _circle"
          matTooltip="Modifier le roadbook"
          matTooltipShowDelay="1000"
          i18n
          type="button"
        >
          <i class="fa-solid fa-pencil"></i>
        </button>

        <button
          *ngIf="intervalToRace < 0"
          (click)="validateRoadbook()"
          class="_sp_btn _inline_icon _white _small _circle"
          matTooltip="Valider le roadbook"
          matTooltipShowDelay="1000"
          i18n
          type="button"
        >
          <i class="fa-solid fa-stopwatch"></i>
        </button>

        <button
          (click)="downloadRoadbookPdf()"
          class="_sp_btn _inline_icon _white _small _circle"
          matTooltip="Télécharger le roadbook"
          matTooltipShowDelay="1000"
          i18n
          type="button"
        >
          <i class="fa-solid fa-download"></i>
        </button>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-end mt-3">
      <small>
        Enregistré le {{ roadbook?.updated | date : "dd MMM y" }} à
        {{ roadbook?.updated | date : "HH:mm" }}
      </small>
    </div>
  </div>
</div>
